











import { Component, Prop, Vue } from 'vue-property-decorator';
import DxImage from "../Assets/Image/Image.vue";
import DxLinkWrap from '../LinkWrap/LinkWrap.vue';

@Component({
    name: 'dx-links',
    components: {DxLinkWrap, DxImage},
})
export default class Icon extends Vue
{
    @Prop() links: [];
    @Prop({default: false}) centered: boolean;
    @Prop({default: false}) outline: boolean;
    @Prop({default: true}) rounded: boolean;

};
